const users = [
	{ username: 'Rodrigo', password: 'Rodrigo' },
	{ username: 'Flor', password: 'Flor' },
	// { username: "laura1", password: 'laura1' },
	// { username: "laura", password: 'laura' },
	// { username: "laura2", password: 'laura2' },
	// { username: "lau", password: 'lau' },
	// { username: "karina", password: 'karina' },
	// { username: 'jorge', password: 'jorge' },
	// { username: 'susy', password: 'susy' },
	// { username: 'Carlos Morales', password: 'CarlosMorales' },
	// { username: 'Mayra Martinez', password: 'MayraMartinez' },
	// { username: 'Monica', password: 'Monica' },
	// { username: 'Hector', password: 'Hector' },
	// { username: 'alejandra', password: 'alejandra' },
	// { username: 'alejandro', password: 'alejandro' },
	// { username: 'mire', password: 'mire' },
	// { username: 'ale', password: 'ale' },
	// { username: 'juan', password: 'juan' },
	// { username: 'pepe', password: 'DobleP' },
	// { username: 'daniel', password: 'daniel' },
	// { username: 'flori', password: 'flori' },
	// { username: 'migue', password: 'migue' },
	// { username: 'itzi', password: 'itzi' },
	// { username: 'eca', password: 'eca' },
	// { username: 'pool', password: 'pool' },
	// { username: 'arroyo', password: 'arroyo' },
	// { username: 'jesus', password: 'jesus' },
	// { username: 'dalet', password: 'dalet' },
	// { username: "estefany", password: 'estefany' },
	// { username:"prueba" ,password:"prueba"}
];

export default users;
